/* eslint-disable consistent-return, no-param-reassign */
import { push } from 'connected-react-router'
import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import ActualServices from 'mgr/lib/services/ActualServices'
import AssignmentAndProblemsServices from 'mgr/lib/services/AssignmentAndProblemsServices'
import AvailabilityServices from 'mgr/lib/services/AvailabilityServices'
import EventsServices from 'mgr/lib/services/EventsServices'
import { fetchSeatingAreaTables } from 'mgr/lib/services/SeatingServices'
import { fetchShifts } from 'mgr/lib/services/ShiftServices'
import { fetchShiftReportingPeriods } from 'mgr/lib/services/ShiftReportingPeriodServices'
import { getCategoryFromPersistentId, pickBestShift, rememberShiftPersistentId } from 'mgr/lib/utils/ShiftUtils'
import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'
import queryString from 'query-string'
import { batchActions } from 'svr/common/ReduxUtils'
import { getVenueToday } from 'svr/common/TimeUtil'
import { AccessRulesService, srGet, srPost } from '@sevenrooms/core/api'
import { getShiftReportingPeriodId, getShiftReportingToUrlId } from 'mgr/lib/utils/ShiftReportingPeriodUtils'

export const onRouterLocationChange = location => (dispatch, getState) => {
  const { dayviewState } = getState()
  const pathParts = location.pathname.split('/') // 1: page, 2: venue, 3: date, 4 -> x: route and sub routes, x + 1: shiftcategory
  if (pathParts.length > 3) {
    const dateStr = pathParts[3]
    const date = moment(dateStr, 'MM-DD-YYYY')
    const dayviewDate = dayviewState.date
    if (_.isNil(dayviewDate) || !dayviewDate.isSame(date)) {
      dispatch({
        type: ActionTypes.CHANGE_DATE,
        date,
      })
    }
  }
  if (pathParts.length > 4 && !_.isEmpty(dayviewState.shifts)) {
    const shiftCategory = pathParts[4]
    const shift = _.find(dayviewState.shifts, {
      category: shiftCategory === 'NIGHT' ? 'LEGACY' : shiftCategory,
    })
    if (!_.isNil(shift) && shift.persistent_id !== dayviewState.shiftPersistentId) {
      dispatch({
        type: ActionTypes.CHANGE_SHIFT,
        shift,
      })
    }
  }
}

export const changeDateAction = date => (dispatch, getState) => {
  const state = getState()

  if (date.isSame(state.dayviewState.date)) {
    return Promise.resolve()
  }

  dispatch({
    type: ActionTypes.CHANGE_DATE,
    date,
  })
  dispatch(tryLoadDayAction(state.appState.venue.urlKey, date))
}

export const changeShiftAction = (shift, shiftReportingPeriodId) => (dispatch, getState) => {
  const { dayviewState, appState } = getState()
  const shiftValues = getShiftValues(
    appState.venue,
    appState.venueSettings,
    dayviewState.shifts,
    shift?.persistent_id || undefined,
    shift?.category || undefined,
    dayviewState.shiftReportingPeriods,
    shiftReportingPeriodId
  )
  if (
    (shift?.persistent_id === dayviewState.shiftPersistentId || shiftValues.category === dayviewState.shiftCategory) &&
    shiftReportingPeriodId === dayviewState.shiftReportingPeriodId
  ) {
    return Promise.resolve()
  }
  dispatch({
    type: ActionTypes.CHANGE_SHIFT,
    ...shiftValues,
  })
  dispatch(
    push({
      search: queryString.stringify({
        shift: shiftValues.shiftCategory,
        shiftReportingPeriod: shiftValues.shiftReportingPeriodId || undefined,
      }),
    })
  )
}

export const goToNow = (history, shift) => (dispatch, getState) => {
  const { dayviewState, appState } = getState()
  const date = moment().tz(appState.venue.timezone)
  const urlSplit = history.location.pathname.split('/')
  urlSplit[3] = date.format('MM-DD-YYYY')
  const shiftValues = getShiftValues(
    appState.venue,
    appState.venueSettings,
    dayviewState.todaysShifts,
    shift.persistent_id,
    shift.category,
    dayviewState.shiftReportingPeriods,
    dayviewState.shiftReportingPeriodId
  )
  dispatch({
    type: ActionTypes.GO_TO_NOW,
    ...shiftValues,
    date,
  })
  history.push({
    pathname: `${urlSplit.join('/')}`,
    search: queryString.stringify({
      shift: shift.category,
      shiftReportingPeriod: shiftValues.shiftReportingPeriodId,
    }),
  })
  dispatch(tryLoadDayAction(appState.venue.urlKey, date))
}

export const getTodaysShifts = venue => (dispatch, getState) => {
  const date = moment().tz(venue.timezone)
  return fetchShifts(venue.urlKey, date).then(
    todaysShifts => {
      const latestStore = getState()
      if (!isActiveVenueId(latestStore, venue.urlKey)) {
        window.svrDebug('Ignoring slow request response for getShifts')
        return
      }
      return dispatch(getTodaysShiftsSuccess(todaysShifts))
    },
    () => dispatch(getShiftsFail())
  )
}

export const getTodaysShiftsSuccess = todaysShifts => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.GET_TODAYS_SHIFTS,
    todaysShifts,
  })
}

export const startLoading = (useDisableScreen = true) => ({
  type: GlobalActionTypes.START_LOADING,
  useDisableScreen,
})

export const endLoading = () => ({ type: GlobalActionTypes.END_LOADING })

export const setCoverflowActive = isActive => ({
  type: ActionTypes.SET_COVERFLOW_ACTIVE,
  isActive,
})

export const setDailyNoteActive = isActive => ({
  type: ActionTypes.SET_DAILY_NOTE_ACTIVE,
  isActive,
})

export const updateDailyNote = note => ({
  type: ActionTypes.UPDATE_DAILY_NOTE,
  note,
})

export const saveDailyNote = (venueId, note, date) => dispatch => {
  const errorHandler = error => dispatch(saveDailyNoteFail(venueId, error))

  dispatch(saveDailyNoteBegin())

  return EventsServices.saveDailyNote(venueId, date, note, errorHandler).then(() => dispatch(saveDailyNoteSuccess(venueId, note, date)))
}

export const saveDailyNoteBegin = () => ({
  type: ActionTypes.SAVE_DAILY_NOTE_START,
})

export const saveDailyNoteSuccess = (venueId, note, date) => dispatch => {
  dispatch(GlobalActions.showSuccessMessage('Daily note updated successfully.'))
  dispatch({
    type: ActionTypes.SAVE_DAILY_NOTE_SUCCESS,
    note,
    venueId,
    date,
  })
}

export const saveDailyNoteFail = (venueId, error) => dispatch => {
  dispatch({
    type: ActionTypes.SAVE_DAILY_NOTE_FAIL,
  })

  dispatch(GlobalActions.showErrorMessage(`Error saving daily notes for venue ${venueId}: ${error}`))
}

export const getDailyEventsBegin = () => ({
  type: ActionTypes.GET_DAILY_EVENTS_START,
})

export const getDailyEventsSuccess = (venueId, date, events, note) => ({
  type: ActionTypes.GET_DAILY_EVENTS_SUCCESS,
  venueId,
  date,
  events,
  note,
})

export const getDailyEventsFail = (venueId, error) => dispatch =>
  dispatch(GlobalActions.showErrorMessage(`Error fetching daily notes and events for venue ${venueId}: ${error}`))

export const tryGetDailyEvents = (venueId, date) => dispatch => {
  const errorHandler = error => dispatch(getDailyEventsFail(venueId, error))

  dispatch(getDailyEventsBegin())

  return EventsServices.fetchDailyEventsAndNotes(venueId, date, errorHandler).then(({ venueId, date, events, note }) => {
    dispatch(getDailyEventsSuccess(venueId, date, events, note))
  })
}

export const changeGridTableSort = isAscending => ({
  type: ActionTypes.CHANGE_GRID_TABLE_SORT,
  isAscending,
})

export const addDropDownOpenedAction = (isBookable, rowEntity, order, isUnassigned, boundingRect, dropDownType, blockOrAccessRule) => ({
  type: ActionTypes.ADD_DROPDOWN_OPENED,
  isBookable,
  rowEntity,
  order,
  isUnassigned,
  boundingRect,
  dropDownType,
  blockOrAccessRule,
})

export const addDropDownClosedAction = () => ({
  type: ActionTypes.ADD_DROPDOWN_CLOSED,
})

export const statusDropDownOpenedAction = (actual, boundingRect) => ({
  type: ActionTypes.STATUS_DROPDOWN_OPENED,
  actual,
  boundingRect,
})

export const statusDropDownClosedAction = () => ({
  type: ActionTypes.STATUS_DROPDOWN_CLOSED,
})

export const showConflictDialogAction = (explanation, actionButtonText, conflictActual, nextAction, closeAction) => ({
  type: ActionTypes.SHOW_CONFLICT_DIALOG,
  explanation,
  actionButtonText,
  conflictActual,
  nextAction,
  closeAction,
})

export const closeConflictDialogAction = refreshActualId => ({
  type: ActionTypes.CLOSE_CONFLICT_DIALOG,
  refreshActualId,
})

/* actions for creating stuff */

export const addNewReservationAction = (date, table, order) => ({
  type: ActionTypes.ADD_NEW_RESERVATION,
  date,
  table,
  order,
})

export const addNewRequestAction = date => ({
  type: ActionTypes.ADD_NEW_REQUEST,
  date,
})

export const addNewBlockAction = (date, table, order) => ({
  type: ActionTypes.ADD_NEW_BLOCK,
  date,
  table,
  order,
})

export const showActualAction = actual => ({
  type: ActionTypes.SHOW_ACTUAL,
  actual,
})

export const showBlockAction = block => ({
  type: ActionTypes.SHOW_BLOCK,
  block,
})

export const showAccessRuleAction = rule => ({
  type: ActionTypes.SHOW_ACCESS_RULE,
  rule,
})

export const blockAdded = block => ({ type: ActionTypes.BLOCK_ADDED, block })

export const blockRemoved = blockId => ({
  type: ActionTypes.BLOCK_REMOVED,
  blockId,
})

/* workflow to start an actual move */

export const startMoveActualAction = (dayviewState, availability, actual, boundingRect) => ({
  type: ActionTypes.START_ACTUAL_MOVE_MODE,
  dayviewState,
  availability,
  actual,
  boundingRect,
})

export const cancelMoveActualAction = () => ({
  type: ActionTypes.CANCEL_ACTUAL_MOVE_MODE,
})

/* workflow to load available timeslots */

const getTimesFail = () => ({ type: ActionTypes.AVAILABILITY_GET_TIMES_FAIL })

const getTimesNotReady = () => ({
  type: ActionTypes.AVAILABILITY_GET_TIMES_NOT_READY,
})

const tryGetTimesAction = (actual, venueId, date, shiftPersistentId, partySize, duration, boundingRect, getState) => dispatch => {
  const errHandler = () => dispatch(getTimesFail())
  return AvailabilityServices.fetchAvailabilityTimes(
    actual.id,
    venueId,
    date,
    partySize,
    shiftPersistentId,
    duration,
    undefined,
    actual.arrival_time_sort_order,
    actual.arrival_time_sort_order,
    errHandler
  ).then(data => {
    if (data && !data.error) {
      const latestStore = getState()
      if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date)) {
        window.svrDebug('Ignoring slow request response for getTimes')
        return
      }
      return dispatch(
        batchActions([
          gridDataReady(latestStore.dayviewState, latestStore.appState),
          startMoveActualAction(latestStore.dayviewState, data.availability, actual, boundingRect),
          endLoading(),
        ])
      )
    }
    return dispatch(getTimesNotReady())
  })
}

/* workflow to move an actual */
export const moveActualAction = (venueId, date, actual, boundingRect) => (dispatch, getState) => {
  dispatch(batchActions([startLoading(), suspendGrid()]))

  const latestStore = getState()
  if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date)) {
    window.svrDebug('Ignoring slow request response for getTimes')
    return
  }

  // Move mode without availability check
  if (isGridSimpleMoveMode(latestStore)) {
    return dispatch(
      batchActions([
        gridDataReady(latestStore.dayviewState, latestStore.appState),
        startMoveActualAction(latestStore.dayviewState, null, actual, boundingRect),
        endLoading(),
      ])
    )
  }

  // Get availability for checking valid moves
  const promises = [
    dispatch(
      tryGetTimesAction(actual, venueId, date, actual.shift_persistent_id, actual.max_guests, actual.duration, boundingRect, getState)
    ),
  ]
  return Promise.all(promises)
}

export const completeMoveActualAction = (venueId, actualId, tableIds) => (dispatch, getState) => {
  const promises = [dispatch(tryPostSaveResTableAssignmentAction({ venueId, actualId, tableIds }))]
  return Promise.all(promises).then(() => {
    dispatch(cancelMoveActualAction())
  })
}

/* workflow to initialize a date */
export const tryLoadDayAction = (venueId, date) => (dispatch, getState) => {
  dispatch(batchActions([startLoading(), suspendGrid()]))

  const promises = [
    dispatch(tryGetVenueStaticDataAction(venueId)),
    dispatch(tryGetShiftsAction(venueId, date)),
    dispatch(tryGetShiftReportingPeriodsAction(venueId, date)),
    dispatch(tryGetBlocksAction(venueId, date)),
    dispatch(tryGetDailyEvents(venueId, date)),
  ]

  return Promise.all(promises).then(() => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date)) {
      window.svrDebug('Ignoring slow request response for loadDay')
      return
    }
    onChangeShift(dispatch, getState)
  })
}

/* workflow to initialize a shift change */
export const onChangeShift = (dispatch, getState) => {
  const store = getState()
  const { venueSettings } = store.appState
  const { shiftPersistentId, shiftReportingPeriod, shiftReportingPeriodId } = store.dayviewState
  const showAutoAssignments = venueSettings.autoselect_table
  const showProblemReservations = venueSettings.show_problem_res
  const { date } = store.dayviewState
  const { urlKey, timezone, startOfDayHour } = store.appState.venue
  const isToday = date.isSame(getVenueToday(timezone, startOfDayHour))
  const shiftCategory = getCategoryFromPersistentId(shiftPersistentId)
  rememberShiftPersistentId(store.appState.venue, shiftPersistentId)

  dispatch(batchActions([startLoading(), suspendGrid()]))
  const promises = [
    dispatch(tryGetSeatingAreaTablesAction(urlKey, date, shiftPersistentId)),
    dispatch(tryGetAccessAction(urlKey, date, shiftCategory)),
    dispatch(tryGetActualsAction(urlKey, date, shiftPersistentId, undefined, shiftReportingPeriod)),
    isToday && dispatch(tryGetWaitlistAction(urlKey, date, shiftPersistentId)),
    dispatch(
      tryGetAutoAssignmentsAndProblemsAction(
        urlKey,
        date,
        shiftPersistentId,
        showProblemReservations,
        showAutoAssignments,
        undefined,
        shiftReportingPeriodId
      )
    ),
  ]

  Promise.all(promises).then(() => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, urlKey) || !isActiveDate(latestStore, date)) {
      window.svrDebug('Ignoring slow request response for onChangeShift')
      return
    }
    dispatch(gridDataReady(latestStore.dayviewState, latestStore.appState))
    dispatch(endLoading())
  })
}

/* workflow to refresh an actual */
export const refreshActualsAction = (venueId, date, shiftPersistentId, modifiedActualIds, shiftReportingPeriod) => (dispatch, getState) => {
  const store = getState()
  const { timezone, startOfDayHour } = store.appState.venue
  const isToday = date.isSame(getVenueToday(timezone, startOfDayHour))
  const { venueSettings } = store.appState
  const showAutoAssignments = venueSettings.autoselect_table
  const showProblemReservations = venueSettings.show_problem_res

  dispatch(batchActions([startLoading(), suspendGrid()]))

  const promises = [
    dispatch(tryGetActualsAction(venueId, date, shiftPersistentId, modifiedActualIds, shiftReportingPeriod)),
    isToday && dispatch(tryGetWaitlistAction(venueId, date, shiftPersistentId)),
    dispatch(
      tryGetAutoAssignmentsAndProblemsAction(
        venueId,
        date,
        shiftPersistentId,
        showProblemReservations,
        showAutoAssignments,
        modifiedActualIds && modifiedActualIds.length ? modifiedActualIds[0] : undefined,
        getShiftReportingToUrlId(shiftReportingPeriod)
      )
    ),
  ]

  Promise.all(promises).then(() => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date)) {
      window.svrDebug('Ignoring slow request response for refreshActuals')
      return
    }
    dispatch(batchActions([gridDataReady(latestStore.dayviewState, latestStore.appState), endLoading()]))
  })
}

/* workflow to refresh an actual */
export const refreshAutoAssignments = (venueId, date, shiftPersistentId, shiftReportingPeriodId) => (dispatch, getState) => {
  const store = getState()
  const { venueSettings } = store.appState
  const showAutoAssignments = venueSettings.autoselect_table
  const showProblemReservations = venueSettings.show_problem_res

  dispatch(batchActions([startLoading(), suspendGrid()]))

  const promises = [
    dispatch(
      tryGetAutoAssignmentsAndProblemsAction(
        venueId,
        date,
        shiftPersistentId,
        showProblemReservations,
        showAutoAssignments,
        undefined,
        shiftReportingPeriodId
      )
    ),
  ]

  Promise.all(promises).then(() => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date)) {
      window.svrDebug('Ignoring slow request response for refreshAutoAssignments')
      return
    }
    dispatch(batchActions([gridDataReady(latestStore.dayviewState, latestStore.appState), endLoading()]))
  })
}

export const suspendGrid = () => ({ type: ActionTypes.SUSPEND_GRID })

export const gridDataReady = (dayviewState, appState) => ({ type: ActionTypes.GRID_DATA_READY, dayviewState, appState })

const isGridSimpleMoveMode = store => store.appState.venue.useGridSimpleMoveMode

const isActiveVenueId = (store, venueId) => {
  const activeVenue = store.appState.venue
  return activeVenue.id === venueId || activeVenue.urlKey === venueId
}

const isActiveDate = (store, date) => {
  const activeDate = store.dayviewState.date
  return date.isSame(activeDate)
}

const isActiveShift = (store, shiftPersistentId) => {
  const activeShiftPersistentId = store.dayviewState.shiftPersistentId
  return activeShiftPersistentId === shiftPersistentId
}

const isActiveShiftCategory = (store, shiftCategory) => {
  const activeShiftPersistentId = store.dayviewState.shiftPersistentId
  const activeShiftCategory = getCategoryFromPersistentId(activeShiftPersistentId)
  return activeShiftCategory === shiftCategory
}

const getShiftValues = (
  venue,
  venueSettings,
  shifts,
  shiftPersistentId,
  shiftCategory,
  shiftReportingPeriods = [],
  shiftReportingPeriodId = ''
) => {
  let shiftReportingPeriod
  if (shiftReportingPeriodId && shiftReportingPeriods) {
    const parts = shiftReportingPeriodId.split('-')
    if (parts.length >= 3) {
      const shift_name = parts.pop()
      const period_type = parts.pop()
      const id = parts.join('-')
      shiftReportingPeriod = _.find(shiftReportingPeriods, { id, period_type, shift_name })
      shiftCategory = shiftReportingPeriod?.shift_type || shiftCategory
    }
  }
  const { isNightlifeClass } = venue
  const { statusesByDb } = venueSettings
  let updatedShiftPersistentId = shiftPersistentId
  let updatedShiftCategory = shiftCategory
  const idx = _.findIndex(shifts, { category: shiftCategory })
  const shiftsByPersistentId = {}
  let bestShift
  if (shifts.length > 0) {
    if (idx === -1) {
      bestShift = pickBestShift(venue, shifts) || {}
      updatedShiftPersistentId = bestShift.persistent_id
      updatedShiftCategory = bestShift.category
    } else {
      updatedShiftPersistentId = shifts[idx].persistent_id
    }
  }

  for (const shift of shifts) {
    shiftsByPersistentId[shift.persistent_id] = shift
  }
  return {
    venue,
    shifts,
    shiftsByPersistentId,
    shiftPersistentId: updatedShiftPersistentId,
    shiftCategory: updatedShiftCategory,
    isNightlifeClass,
    statusesByDb,
    shiftReportingPeriod,
    shiftReportingPeriodId,
    shiftReportingPeriods,
  }
}

/* SHIFTS */
export const getShiftsSuccess = (venue, venueSettings, shifts) => (dispatch, getState) => {
  const state = getState()
  const shiftValues = getShiftValues(
    venue,
    venueSettings,
    shifts,
    state.dayviewState.shiftPersistentId,
    state.dayviewState.shiftCategory,
    state.dayviewState.shiftReportingPeriods,
    state.dayviewState.shiftReportingPeriodId
  )
  dispatch({
    type: ActionTypes.GET_SHIFTS_SUCCESS,
    ...shiftValues,
  })
  dispatch(
    push({
      search: queryString.stringify({
        shift: shiftValues.shiftCategory,
        shiftReportingPeriod: shiftValues.shiftReportingPeriodId || undefined,
      }),
    })
  )

  if (shifts.length === 0) {
    dispatch(GlobalActions.showSuccessMessage('No shifts on this date'))
  }
}

export const getShiftsFail = () => ({ type: ActionTypes.GET_SHIFTS_FAIL })

export const tryGetShiftsAction = (venueId, date) => (dispatch, getState) =>
  fetchShifts(venueId, date).then(
    shifts => {
      const latestStore = getState()
      if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date)) {
        window.svrDebug('Ignoring slow request response for getShifts')
        return
      }
      const { venue, venueSettings } = latestStore.appState
      return dispatch(getShiftsSuccess(venue, venueSettings, shifts))
    },
    e => {
      dispatch(getShiftsFail())
      throw e
    }
  )

/* Shift Reporting Periods */
export const getShiftReportingPeriodsSuccess = (venue, venueSettings, shiftReportingPeriods) => (dispatch, getState) => {
  const state = getState()
  const shiftValues = getShiftValues(
    venue,
    venueSettings,
    state.dayviewState.shifts,
    state.dayviewState.shiftPersistentId,
    state.dayviewState.shiftCategory,
    shiftReportingPeriods,
    state.dayviewState.shiftReportingPeriodId
  )
  dispatch({
    type: ActionTypes.GET_SHIFT_REPORTING_PERIODS_SUCCESS,
    ...shiftValues,
  })
  dispatch(
    push({
      search: queryString.stringify({
        shift: shiftValues.shiftCategory,
        shiftReportingPeriod: shiftValues.shiftReportingPeriodId || undefined,
      }),
    })
  )
}
export const getShiftReportingPeriodFail = () => ({ type: ActionTypes.GET_SHIFT_REPORTING_PERIODS_FAIL })

export const tryGetShiftReportingPeriodsAction = (venueId, date) => (dispatch, getState) =>
  fetchShiftReportingPeriods(venueId, date).then(
    shiftReportingPeriods => {
      const latestStore = getState()
      const { venue, venueSettings } = latestStore.appState
      return dispatch(getShiftReportingPeriodsSuccess(venue, venueSettings, shiftReportingPeriods))
    },
    () => dispatch(getShiftReportingPeriodFail())
  )
/* BLOCKS */
export const fetchBlocks = (venueId, date, errHandler) => {
  const dateParam = date.format('MM-DD-YYYY')
  return srGet(`/manager/${venueId}/blocks/date/${dateParam}`, {}).then(
    response =>
      // TODO: fix this
      // if (response.status!=200 && errHandler) {
      //  errHandler(response)
      // }
      response
  )
}

export const getBlocksSuccess = (venue, blocks) => ({ type: ActionTypes.GET_BLOCKS_SUCCESS, venue, blocks })

export const getBlocksFail = () => ({ type: ActionTypes.GET_BLOCKS_FAIL })

export const tryGetBlocksAction = (venueId, date) => (dispatch, getState) => {
  const errHandler = () => dispatch(getBlocksFail())
  return fetchBlocks(venueId, date, errHandler).then(blocks => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date)) {
      window.svrDebug('Ignoring slow request response for getBlocks')
      return
    }
    const { venue } = getState().appState
    return dispatch(getBlocksSuccess(venue, blocks))
  })
}

/* ACCESS */
export const fetchAccess = (venueId, date, shiftCategory, errHandler) => {
  const dateParam = date.format('MM-DD-YYYY')
  const dateParamResponse = date.format('MM/DD/YYYY')

  return srGet(`/api-yoa/booking_access`, {
    venue: venueId,
    start_date: dateParam,
    end_date: dateParam,
    ...(shiftCategory && { shift_category: shiftCategory }),
  }).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response)
    }

    return response.data.access[dateParamResponse]
  })
}

export const getAccessSuccess = (venue, access, isSingleShift = true) => ({
  type: ActionTypes.GET_ACCESS_SUCCESS,
  venue,
  access,
  isSingleShift,
})

export const getAccessFail = () => ({ type: ActionTypes.GET_ACCESS_FAIL })

export const tryGetAccessAction =
  (venueId, date, shiftCategory = null) =>
  (dispatch, getState) => {
    const errHandler = () => dispatch(getAccessFail())

    return fetchAccess(venueId, date, shiftCategory, errHandler).then(access => {
      const latestStore = getState()
      if (
        !isActiveVenueId(latestStore, venueId) ||
        !isActiveDate(latestStore, date) ||
        (shiftCategory && !isActiveShiftCategory(latestStore, shiftCategory))
      ) {
        window.svrDebug('Ignoring slow request response for getAccess')
        return
      }
      const { venue } = latestStore.appState

      return dispatch(getAccessSuccess(venue, access, !!shiftCategory))
    })
  }

export const loadAccessRules = (venueId, date) => dispatch =>
  batchActions([dispatch(startLoading()), dispatch(tryGetAccessAction(venueId, date)).then(() => dispatch(endLoading()))])

/* Day view data */

export const fetchVenueStaticData = (venueId, errHandler) =>
  srGet(`/api-yoa/venue_static_data`, { venue: venueId }).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response)
    }
    return response.data
  })

export const getVenueStaticDataSuccess = staticData => ({ type: GlobalActionTypes.GET_VENUE_STATIC_DATA_SUCCESS, staticData })

export const getVenueStaticDataFail = () => ({ type: GlobalActionTypes.GET_VENUE_STATIC_DATA_FAIL })

export const tryGetVenueStaticDataAction = venueId => (dispatch, getState) => {
  const errHandler = () => dispatch(getVenueStaticDataFail())
  return fetchVenueStaticData(venueId, errHandler).then(staticData => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, venueId)) {
      window.svrDebug('Ignoring slow request response for getVenueStaticData')
      return
    }
    return dispatch(getVenueStaticDataSuccess(staticData))
  })
}

/* Seating Areas and Tables */

export const getSeatingAreaTablesSuccess = seatingAreaTables => ({
  type: ActionTypes.GET_SEATINGAREA_TABLES_SUCCESS,
  seatingAreaTables,
})

export const getSeatingAreaTablesFail = () => ({ type: ActionTypes.GET_SEATINGAREA_TABLES_FAIL })

export const tryGetSeatingAreaTablesAction = (venueId, date, shiftPersistentId) => {
  const includeLayout = true
  return (dispatch, getState) =>
    fetchSeatingAreaTables(venueId, date, shiftPersistentId, includeLayout).then(
      seatingAreaTables => {
        const latestStore = getState()
        if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date) || !isActiveShift(latestStore, shiftPersistentId)) {
          window.svrDebug('Ignoring slow request response for getSeatingAreaTables')
          return
        }
        return dispatch(getSeatingAreaTablesSuccess(seatingAreaTables))
      },
      () => dispatch(getSeatingAreaTablesFail())
    )
}

/* ACTUALS */

export const fetchActuals = (venueId, date, shiftPersistentId, modifiedActualIds, shiftReportingPeriod, errHandler) => {
  const dateParam = moment(date).format('MM-DD-YYYY')
  return srGet(`/api-yoa/actuals/day`, {
    venue: venueId,
    date: dateParam,
    shift_persistent_id: !shiftReportingPeriod ? shiftPersistentId : undefined,
    modified_actual_ids: modifiedActualIds ? modifiedActualIds.join() : null,
    shift_reporting_period: getShiftReportingPeriodId(shiftReportingPeriod),
    include_canceled: 1,
  }).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response)
    }
    return response.data
  })
}

export const getActualsSuccess = (actuals, shiftPersistentId) => ({ type: ActionTypes.GET_ACTUALS_SUCCESS, actuals, shiftPersistentId })

export const getActualsFail = () => ({ type: ActionTypes.GET_ACTUALS_FAIL })

export const getActualsNotReady = () => ({ type: ActionTypes.GET_ACTUALS_NOT_READY })

export const tryGetActualsAction = (venueId, date, shiftPersistentId, modifiedActualIds, shiftReportingPeriod) => (dispatch, getState) => {
  const errHandler = () => dispatch(getActualsFail())
  return fetchActuals(venueId, date, shiftPersistentId, modifiedActualIds, shiftReportingPeriod, errHandler).then(data => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date) || !isActiveShift(latestStore, shiftPersistentId)) {
      window.svrDebug('Ignoring slow request response for getActuals')
      return
    }
    if (data.success) {
      return dispatch(getActualsSuccess(data.actuals, shiftPersistentId))
    }
    return dispatch(getActualsNotReady())
  })
}

/* WAITLIST */

export const fetchWaitlist = (venueId, date, shiftPersistentId, modifiedWaitlistIds, errHandler) => {
  const dateParam = moment(date).format('MM-DD-YYYY')
  return srGet(`/api-yoa/waitlist/day`, {
    venue: venueId,
    date: dateParam,
    shift_persistent_id: shiftPersistentId,
    modified_waitlist_ids: modifiedWaitlistIds ? modifiedWaitlistIds.join() : null,
    include_canceled: 0,
    include_seated: 0,
  }).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response)
    }
    return response.data
  })
}

export const getWaitlistSuccess = (waitlist_entries, shiftPersistentId) => ({
  type: ActionTypes.GET_WAITLIST_SUCCESS,
  waitlist_entries,
  shiftPersistentId,
})

export const getWaitlistFail = () => ({ type: ActionTypes.GET_WAITLIST_FAIL })

export const getWaitlistNotReady = () => ({ type: ActionTypes.GET_WAITLIST_NOT_READY })

export const tryGetWaitlistAction = (venueId, date, shiftPersistentId, modifiedWaitlistIds) => (dispatch, getState) => {
  const errHandler = () => dispatch(getWaitlistFail())
  return fetchWaitlist(venueId, date, shiftPersistentId, modifiedWaitlistIds, errHandler).then(data => {
    const latestStore = getState()
    if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date) || !isActiveShift(latestStore, shiftPersistentId)) {
      window.svrDebug('Ignoring slow request response for getWaitlist')
      return
    }
    if (data.success) {
      data.waitlist_entries.map(w => {
        w.is_waitlist_entry = true
        return w
      })
      return dispatch(getWaitlistSuccess(data.waitlist_entries, shiftPersistentId))
    }
    return dispatch(getWaitlistNotReady())
  })
}

/* Seating Areas and Tables */

export const getAutoAssignmentsAndProblemsSuccess = (autoAssignAndProblems, shiftPersistentId) => ({
  type: ActionTypes.GET_AUTOASSIGN_PROBLEMS_SUCCESS,
  autoAssignAndProblems,
  shiftPersistentId,
})

export const getAutoAssignmentsAndProblemsFail = () => ({ type: ActionTypes.GET_AUTOASSIGN_PROBLEMS_FAIL })

export const tryGetAutoAssignmentsAndProblemsAction =
  (venueId, date, shiftPersistentId, showProblemRes, showAutoAssignments, forceUpdateActualId, shiftReportingPeriodUrlId) =>
  (dispatch, getState) => {
    const errHandler = () => dispatch(getAutoAssignmentsAndProblemsFail())
    return AssignmentAndProblemsServices.fetchAutoAssignmentsAndProblems(
      venueId,
      date,
      shiftPersistentId,
      showProblemRes,
      showAutoAssignments,
      forceUpdateActualId,
      shiftReportingPeriodUrlId,
      errHandler
    ).then(autoAssignAndProblems => {
      const latestStore = getState()
      if (!isActiveVenueId(latestStore, venueId) || !isActiveDate(latestStore, date) || !isActiveShift(latestStore, shiftPersistentId)) {
        window.svrDebug('Ignoring slow request response for getAutoAssignmentsAndProblems')
        return
      }
      return dispatch(getAutoAssignmentsAndProblemsSuccess(autoAssignAndProblems, shiftPersistentId))
    })
  }

/* Res status changes */

export const saveResStatusSuccess = statusResponse => ({
  type: ActionTypes.SAVE_RES_STATUS_SUCCESS,
  actualId: statusResponse.actual_id,
  status: statusResponse.status,
  statusFormatted: statusResponse.status_formatted,
  isInService: statusResponse.is_in_service,
  isCanceled: statusResponse.is_canceled,
})

export const saveResStatusFail = () => ({ type: ActionTypes.SAVE_RES_STATUS_FAIL })

export const tryPostSaveResStatusAction = (venueId, actualId, status) => (dispatch, getState) => {
  dispatch(batchActions([startLoading(), suspendGrid()]))
  const errHandler = () => {
    const latestStore = getState()
    dispatch(batchActions([saveResStatusFail(), gridDataReady(latestStore.dayviewState, latestStore.appState), endLoading()]))
  }
  return ActualServices.postResStatusChange(venueId, actualId, status, errHandler).then(response =>
    dispatch(saveResStatusSuccess(response))
  )
}

export const saveResTableAssignmentSuccess = statusResponse => ({
  type: ActionTypes.SAVE_RES_TABLE_ASSIGNMENT_SUCCESS,
  actualId: statusResponse.actual_id,
  tableIds: statusResponse.tableIds,
})

export const saveResTableAssignmentFail = () => ({ type: ActionTypes.SAVE_RES_TABLE_ASSIGNMENT_FAIL })

export const tryPostSaveResTableAssignmentAction =
  ({ venueId, actualId, tableIds, isAutoAssign }) =>
  (dispatch, getState) => {
    dispatch(batchActions([startLoading(), suspendGrid()]))
    const errHandler = errMsg => {
      const latestStore = getState()
      dispatch(
        batchActions([
          GlobalActions.showErrorMessage(errMsg),
          saveResTableAssignmentFail(),
          gridDataReady(latestStore.dayviewState, latestStore.appState),
          endLoading(),
        ])
      )
    }
    const isCustomAssign = false
    return ActualServices.postResTableAssignment({
      venueId,
      actualId,
      tableIds,
      isAutoAssign,
      isCustomAssign,
      errHandler,
    }).then(response => {
      if (response) {
        return dispatch(saveResTableAssignmentSuccess(response))
      }
    })
  }

/* Res duration changes */

export const postResDuration = (venueId, actualId, duration, errHandler) => {
  const url = `/api-yoa/actual/${actualId}/duration`
  return srPost(url, {
    venue: venueId,
    duration: duration || 0,
  }).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response)
    }
    return response.data
  })
}

export const saveResDurationSuccess = statusResponse => ({
  type: ActionTypes.SAVE_RES_DURATION_SUCCESS,
  actualId: statusResponse.actual_id,
  duration: statusResponse.duration,
})

export const saveResDurationFail = () => ({ type: ActionTypes.SAVE_RES_DURATION_FAIL })

export const tryPostSaveResDurationAction = (venueId, actualId, duration) => (dispatch, getState) => {
  dispatch(batchActions([startLoading(), suspendGrid()]))
  const errHandler = () => {
    const latestStore = getState()
    dispatch(batchActions([saveResDurationFail(), gridDataReady(latestStore.dayviewState, latestStore.appState), endLoading()]))
  }
  return postResDuration(venueId, actualId, duration, errHandler).then(response => dispatch(saveResDurationSuccess(response)))
}

export const tryGetAccessRuleAction = (id, venueId, date) => dispatch => {
  dispatch(startLoading())
  AccessRulesService.getAccessRule({ id, venueId, date }).then(selectedAccessRule =>
    dispatch(batchActions([endLoading(), setSelectedAccessRule(selectedAccessRule)]))
  )
}

export const setSelectedAccessRule = selectedAccessRule => ({
  type: ActionTypes.SET_SELECTED_ACCESS_RULE,
  selectedAccessRule,
})

export const loadAndRefreshData = (venueId, date) => (dispatch, getState) => {
  const store = getState()
  const { shiftPersistentId, shiftReportingPeriodId } = store.dayviewState
  const { venueSettings } = store.appState
  const { urlKey } = store.appState.venue
  const showAutoAssignments = venueSettings.autoselect_table
  const showProblemReservations = venueSettings.show_problem_res
  const shiftCategory = getCategoryFromPersistentId(shiftPersistentId)

  dispatch(batchActions([startLoading(), suspendGrid()]))
  Promise.all([
    dispatch(tryGetAccessAction(venueId, date, shiftCategory)),
    dispatch(
      tryGetAutoAssignmentsAndProblemsAction(
        urlKey,
        date,
        shiftPersistentId,
        showProblemReservations,
        showAutoAssignments,
        undefined,
        shiftReportingPeriodId
      )
    ),
  ]).then(() => {
    const latestStore = getState()
    dispatch(gridDataReady(latestStore.dayviewState, latestStore.appState))
    dispatch(endLoading())
  })
}
